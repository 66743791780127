import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "../assets/css/style.css";
import "../assets/js/main";
import "../assets/scss/style.scss";
import {
  ChevronRight,
  ArrowUpShort,
  Twitter,
  X,
  Facebook,
  Linkedin,
  Instagram,
} from "react-bootstrap-icons";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Footer() {
  return (
    <>
      <footer id="footer">
        <div className="site-footer bg-dark">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <hr/>
                {/* <h2 className="footer-heading mb-4 text-light">Contact</h2> */}
                
            <Link to="/">
              <div className="mb-2 mt-2 site-logo">
              <img
                src={require("../assets/images/mypassion4seniorswhite.png")}
                width={"300px"}
                alt="logo"
              />
            </div>
            </Link>
            <hr/>
            <p className="text-warning">License No: PHCP011875</p>
            <hr/>
                <p className="text-info">
                  Address: P.O. Box 720571, Atlanta, GA 30358
                </p>
                <p className="text-info">
                <a href="tel:+4042541634" className="link-offset-2 link-underline link-underline-opacity-10 link-info">Phone: (404) 254-1634</a>
                </p>
                <p className="text-info">
                <a href="mailto:info@mypassionforseniorsllc.com" className="link-offset-2 link-underline link-underline-opacity-10 link-info">Email: info@mypassionforseniorsllc.com</a>
                </p>
                <div className="my-5 social">
                  <a href="#" className="pl-0 pe-3 text-info">
                    <span className="icon-facebook"><Facebook/></span>
                  </a>
                  <a href="#" className="ps-3 pe-3 text-info">
                    <span className="icon-twitter"><Twitter/></span>
                  </a>
                  <a href="#" className="ps-3 pe-3 text-info">
                    <span className="icon-instagram"><Instagram/></span>
                  </a>
                  <a href="#" className="ps-3 pe-3 text-info">
                    <span className="icon-linkedin"><Linkedin/></span>
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                
                <div className="row">
                  <div className="col-lg-4">
                    <hr/>
                    <h2 className="footer-heading mb-4 text-light">
                      Quick Links
                    </h2>
                    <ul className="list-unstyled">
                      {/* <li>
                        <a href="#" className="text-light link-offset-2 link-underline link-underline-opacity-10 link-light">
                          Amazing Atmosphere
                        </a>
                      </li>
                      <li>
                        <a href="#" className="text-light link-offset-2 link-underline link-underline-opacity-10 link-light">
                          Caring Staff
                        </a>
                      </li>
                      <li>
                        <a href="#" className="text-light link-offset-2 link-underline link-underline-opacity-10 link-light">
                          FAQs
                        </a>
                      </li> */}
                      <li>
                        <Link to="/about" className="text-light link-offset-2 link-underline link-underline-opacity-10 link-light">
                          About Us
                        </Link>
                      </li>
                      <li>
                        <HashLink to="/about#companionship" className="text-light link-offset-2 link-underline link-underline-opacity-10 link-light">
                          Companionship Care
                        </HashLink>
                      </li>
                      <li>
                        <HashLink to="/about#homemaking" className="text-light link-offset-2 link-underline link-underline-opacity-10 link-light">
                          Homemaking Care
                        </HashLink>
                      </li>
                      <li>
                        <HashLink to="/about#personal" className="text-light link-offset-2 link-underline link-underline-opacity-10 link-light">
                          Personal Care
                        </HashLink>
                      </li>
                      <li>
                        <HashLink to="/about#privateduty" className="text-light link-offset-2 link-underline link-underline-opacity-10 link-light">
                          24/7 Private Duty
                        </HashLink>
                      </li>
                      <li>
                        <HashLink to="/#contact" className="text-light link-offset-2 link-underline link-underline-opacity-10 link-light">
                          Contact Us
                        </HashLink>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="col-lg-4">
                    <hr/>
                    <h2 className="footer-heading mb-4 text-light">
                      Helpful Links
                    </h2>
                    <ul className="list-unstyled">
                      
                      <li>
                        <a href="#" className="text-light link-offset-2 link-underline link-underline-opacity-10 link-light">
                          Privacy
                        </a>
                      </li>
                      <li>
                        <a href="#" className="text-light link-offset-2 link-underline link-underline-opacity-10 link-light">
                          Terms
                        </a>
                      </li>
                    </ul>
                  </div> */}
                  
                  {/* <div className="col-lg-4">
                    <hr/>
                    <h2 className="footer-heading mb-4 text-light link-offset-2 link-underline link-underline-opacity-10 link-light">
                      Resources
                    </h2>
                    <ul className="list-unstyled">
                      <li>
                        <a href="#" className="text-light link-offset-2 link-underline link-underline-opacity-10 link-light">
                          Health Care
                        </a>
                      </li>
                      <li>
                        <a href="#" className="text-light link-offset-2 link-underline link-underline-opacity-10 link-light">
                          Elderly Care
                        </a>
                      </li>
                      <li>
                        <a href="#" className="text-light link-offset-2 link-underline link-underline-opacity-10 link-light">
                          Care with Love
                        </a>
                      </li>
                      <li>
                        <a href="#" className="text-light link-offset-2 link-underline link-underline-opacity-10 link-light">
                          Quality Care
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-md-12">

                <div className="border-top pt-5">
                  <p className="copyright text-secondary">
                    <small>
                      Copyright &copy;{" "}
                      {new Date().getFullYear()} My Passion for Seniors LLC. 
                      All rights reserved | This template is made by{" "}
                      <a href="https://colorlib.com" target="_blank" rel="noreferrer">
                        Colorlib
                      </a>
                    </small> <a href="https://www.gnotable.ng" className="link-offset-2 link-underline link-underline-opacity-10 link-warning">Powered by Gnotable Technologies</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export { Footer };
