import "bootstrap/dist/css/bootstrap.css";
import "../assets/css/style.css";
import "../assets/js/main";
import Hero from "../components/hero";
import { About } from "../components/about";
import { Features, Services } from "../components/services";
import Cta from "../components/cta";
import Testimonials from "../components/testimonials";
import { Footer } from "../components/footer";
import { Header } from "../components/header";
import { Stats } from "../components/stats";
import { ContactUs } from "../components/forms";
import Payment from "../components/payments";
import Completion from "../components/checkoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { useState, useRef, useEffect } from "react";
import { Mastercard, Visa, Paypal, Unionpay, Discover, Amex } from 'react-payment-logos/dist/logo';

// require('dotenv').config()

const stripePromise = loadStripe(
  "pk_test_51PK1hjP1uCH0MjA8HN1ALrV5omyKqEq7IWG59UI4f1CWnzvKGqXzdc6GuG10VPl7IkTucvsYWKXP4adxTknM2RTO00rEkhEghU"
);
console.log(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function Home() {
  const [stripeError, setStripeError] = useState();
  const [loading, setLoading] = useState();
  const inputRef = useRef();

  const handleCheckout = async (event) => {
    // const stripe = await getStripe();
    setLoading(true);
    const stripe = await stripePromise;

    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: "price_1PLM7YP1uCH0MjA8VtaETHuA",
          quantity: parseInt(inputRef.current.value),
        },
      ],
      mode: "payment",
      successUrl: window.location.href + `/completion`,
      cancelUrl: window.location.href,
    });

    if (error) {
      setStripeError(error);
      setLoading(false);
    }
    console.warn(error.message);
  };


  return (
    <>
      {/* <div id="overlayer"></div>
                <div className="loader">
                    <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                    </div>
                </div> */}

      <div className="site-wrap">
        <div className="site-mobile-menu site-navbar-target">
          <div className="site-mobile-menu-header">
            <div className="site-mobile-menu-close mt-3">
              <span className="icon-close2 js-menu-toggle"></span>
            </div>
          </div>
          <div className="site-mobile-menu-body"></div>
        </div>
        <Header />
        <main id="main">
          <Hero />
          <Features />
          <Services />
          <Stats />
          <About />
          <Cta />

          {/* <div className="container" id="pay">
            <Mastercard width={100} height={100}/><Amex width={100} height={100}/><Unionpay width={100} height={100}/><Visa width={100} height={100}/><Discover width={100} height={100}/>
            <form className="p-5 flex-grow-1">
              <div className="row justify-content-center">
                <div className="col-auto">
                  {stripeError && (
                    <p style={{ color: "red" }}>{stripeError.message}</p>
                  )}
                  <input type="number" min={1} className="form-control mb-2 text-center" ref={inputRef} placeholder="Number of hours"/>
                </div>
                <div className="col-auto">
                  <button
                    role="link"
                    onClick={handleCheckout}
                    disabled={loading}
                    className="btn btn-primary"
                  >
                    Pay Now
                  </button>
                </div>
              </div>
            </form>
          </div> */}
          {/* <Payment stripePromise={stripePromise} /> */}
          {/* <Completion stripePromise={stripePromise} /> */}
          <Testimonials />
        </main>
        <ContactUs />
        <Footer />
      </div>
    </>
  );
}

export default Home;
